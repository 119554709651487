import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { getConfigParam } from '../utils';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
var devToolsOptions = {
  features: {
    dispatch: getConfigParam('env') !== 'production',
    // dispatch custom actions or action creators
    export: true,
    // export history of actions in a file
    import: 'custom',
    // import history of actions from a file
    jump: true,
    // jump back and forth (time travelling)
    lock: true,
    // lock/unlock dispatching actions and side effects
    pause: true,
    // start/pause recording of dispatched actions
    persist: true,
    // persist states on page reloading
    reorder: true,
    // drag and drop actions in the history list
    skip: true,
    // skip (cancel) actions
    test: true // generate tests for the selected actions
  }
};

var configureStore = function configureStore(preloadedState) {
  var middlewares = [thunkMiddleware];
  var middlewareEnhancer = applyMiddleware.apply(void 0, middlewares);
  var enhancers = [middlewareEnhancer];
  var composedEnhancers = composeWithDevTools(devToolsOptions);
  var store = createStore(rootReducer, preloadedState, composedEnhancers.apply(void 0, enhancers));
  if (getConfigParam('env') !== 'production') {
    window.store = store;
  }
  return store;
};
export default configureStore;